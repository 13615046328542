<!-- 页头区域 -->
<template>

  <!-- 抬头 -->
  <div class="app-title">
    <div class="app-title-container">

      <div class="t-left">
        <!-- <img src="../assets/image/logo/logo.jpg" alt=""> -->
        <div class="t-left-logo">
          <img src="../assets/image/logo-1.jpg" alt="" srcset="">
        </div>
      </div>

      <div class="t-right">
        <div class="t-right-search">
          <!-- 查询 -->
          <div class="right-search-1">
            学生证书查询:
          </div>
          <!-- 输入框 -->
          <div class="right-search-2">
            <el-input v-model="input" placeholder="请输入姓名" style="width: 117px; margin-left: 4px;" />
            <!-- 确认按钮 -->
            <el-button color="#ff0000" style="width: 74px; margin-left: 6px;" @click="zscx">查询</el-button>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- 菜单 -->
  <div class="app-menu">
    <div class="menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#9a0201"
        text-color="#fff"
        active-text-color="#fff"
        @select="handleSelect"
      >
        <el-menu-item index="1">
          网站首页   
        </el-menu-item>
        <el-menu-item index="2">
          证书查询 
        </el-menu-item>
        <el-menu-item :index="String(item._id)" v-for="(item,index) in menuList" :key="index" @click="goToPage(item._id,item.menu_name)">
            {{ item.menu_name }}
        </el-menu-item>

      </el-menu>
    </div>  
  </div>

  <!-- 轮播图 -->
  <div class="banner">
    <div class="banner-img">
      <el-carousel height="500px">
        <el-carousel-item style="height: 500px;min-width: 1200px; max-width: 1920px;">
          <img src="../assets/image/banner/banner1.jpg" style="height: 500px;min-width: 1200px; max-width: 1920px;" alt=""> 
        </el-carousel-item>
        <el-carousel-item style="height: 500px;min-width: 1200px; max-width: 1920px;">
          <img src="../assets/image/banner/banner2.jpg" style="height: 500px;min-width: 1200px; max-width: 1920px;" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>

</template>

<script>
import { getMenu } from "@/api/index.js";
export default {
  name: 'AppTitle',
  props: {

  },
  data() {
    return {
      input: "",          // 查证书的inpit
      activeIndex: "0",   // 默认选中的菜单
      menuList: [         // 菜单list
      ]        
    }
  },
  async mounted() {
    await this.getMenuList();
  },
  methods: {

    // 菜单选择方法
    handleSelect(key,keyPath) {
      console.log(key, keyPath)
      if(key == '1') {
        this.$router.push({path: '/'});         // 首页
      }
      else if(key == '2') {
        this.$router.push({path: '/zscx'});      // 证书查询
      } else {
        console.log('other');
      }

    },

    // 路由跳转
    goToPage(sid,menu_name) {
      console.log(menu_name)
      this.$router.push({path: '/news', query: {t: Date.parse(new Date()),sid: sid,menu_name: menu_name}});
    },

    // get菜单list
    getMenuList() {
      getMenu().then(res=> {
        // console.log(res.data.data[0].menu);
        // 过滤状态停用
        let _list = res.data.data[0].menu.filter(function(val){
          return val.menu_status === 0;
        });
        // console.log(_list)
        this.menuList = _list;
        

        // if(res.data.code == 1) {
        //   console.log(res.data.data)
        // } else {
        //   console.log('err');
        // }

      })
    },
    // 证书查询
    zscx() {
      let name = this.input || "";
      if(name !== "" && name !== undefined && name !== null) {
        this.$router.push({path: '/zscx', query: {name: name}});
        // GetZscx({name: name}).then(r=> {
        //   console.log(r.data.data[0])
        // })
      } else {
        console.log('请输入姓名');
      }
    }

  }
}
</script>

<style>

  /**宽度1200px */
  .app-title {
    display: flex;
    height: 115px;
    background-color: #f8f8f8;
  }
  .app-title-container {
    display: flex;
    height: 115px;
    width: 1200px;
    margin: 0 auto;
  }
  /** title 左 */
  .app-title-container > .t-left {
    float: left;
    width: 684px;
    padding-top: 21px;
  }
  .t-left > .t-left-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 580px;
    height: 73px;
    font-size: 22px;
    background-color: #dfdfdf;color: #ffffff;
    letter-spacing: 4px;
    border-radius: 8px;
  }
  .t-left > .t-left-logo img {
    width: 580px;
    height: 73px;
  }
  .app-title-container > .t-left img {
    width: 580px;
    height: 73px;
  }

  /** title 右 */
  .app-title-container > .t-right {
    margin-top: 47px;
    float: right;
    height: 36px;
    width: 530px;
  }

  .t-right > .t-right-search {
    display: flex;
    flex-direction: row;
    height: 36px;
    width: 460px;
    margin-left: 60px;
  }

  .t-right-search > .right-search-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    margin-left: 58px;
    letter-spacing: 1px;
  }
  .t-right-search > .right-search-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .t-right-search > .right-search-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /**
    * 菜单
    */
  .app-menu {
    height: 59px;
    background-color: #9a0201;
  }
  .menu {
    width: 1200px;
    margin: 0 auto;
  }

  .el-menu-item,.el-sub-menu__title {
    font-size: 16px !important;

  }
  .el-menu-item .is-active {
    color: #9a0201 !important;
  }

  .el-menu-item:hover {
    background-color: #000000 !important;
  }
  .el-sub-menu__title:hover {
    background-color: #000000 !important;
  }

  /** 
    * 轮播图
    */
  .banner {
    height: 500px;
  }
  .banner > .banner-img {
    height: 500px;
    min-width: 1200px; max-width: 1920px;
    margin: 0 auto;
  }
</style>