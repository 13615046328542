<!--  证书查询  具体内容页 页 -->
<template>
    <div id="app">
        <AppTitle />
        <!-- 新闻页 内容区域 -->
        <div class="news">
          <div class="news-container">
            <!-- 左侧 -->
            <div class="news-L">
              <!-- 竖向菜单 -->
              <div class="news-L-menu">
                <div class="news-L-menu-title">
                  信息分类
                </div>  
                <div class="news-L-menu-list" v-for="(item,index) in menuList" :key="index">
                  <a href="javascript:;" class="news-L-menu-list-a" @click="goToPage(item._id,item.menu_name)"></a>
                  {{ item.menu_name }}
                </div>
              </div>

            </div>
            <!-- 内容 -->
            <div class="news-R">
              <!-- 内容 抬头 显示所在URL -->
              <div class="news-R-title">
                当前位置：<a href="/">网站首页</a> > 证书查询
              </div>
              <!-- 新闻列表list -->
              <div class="news-R-content">
                <!-- 查询条件 -->
                <div class="zscx-view">
                    <div class="zscx-view-1">证书查询：</div>

                    <div class="zscx-view-2">
                        <input type="text" v-model="form.name" placeholder="请输入姓名" autocomplete="off" />
                    </div>
  
                    <a href="javascript:;" class="zscx-view-3" @click="search_zs">
                        查询
                    </a>
                </div>
                <!-- 查询结果 -->
                <div class="zscx-cxjg" v-if="cxjg.length > 0">
                    <div class="cxjg-info"></div>
                    <div class="cxjg-info"><span class="cxjg-info-l">承办单位:</span><span class="cxjg-info-r">{{ cxjg[0].cbdw }}</span> </div>
                    <div class="cxjg-info"><span class="cxjg-info-l">姓名:</span> <span class="cxjg-info-r">{{  cxjg[0].name }}</span></div>
                    <div class="cxjg-info"><span class="cxjg-info-l">拼音:</span> <span class="cxjg-info-r">{{ cxjg[0].py  }}</span></div>
                    <div class="cxjg-info"><span class="cxjg-info-l">国籍:</span> <span class="cxjg-info-r">{{  cxjg[0].gj }}</span></div>
                    <!-- <div class="cxjg-info"><span class="cxjg-info-l">证件类型:</span> <span class="cxjg-info-r">{{ cxjg[0].zjlx  }}</span></div>
                    <div class="cxjg-info"><span class="cxjg-info-l">证件号码:</span> <span class="cxjg-info-r">{{  cxjg[0].zjhm }}</span></div> -->
                    <div class="cxjg-info"><span class="cxjg-info-l">性别:</span> <span class="cxjg-info-r">{{ cxjg[0].xb  }}</span></div>
                    <div class="cxjg-info"><span class="cxjg-info-l">出生日期:</span> <span class="cxjg-info-r">{{  cxjg[0].csrq }}</span></div>
                    <div class="cxjg-info"><span class="cxjg-info-l">民族:</span> <span class="cxjg-info-r">{{ cxjg[0].mz  }}</span></div>
                    <div class="cxjg-info"><span class="cxjg-info-l">专业:</span> <span class="cxjg-info-r">{{  cxjg[0].zy }}</span></div>
                    <div class="cxjg-info"><span class="cxjg-info-l">等级:</span> <span class="cxjg-info-r">{{ cxjg[0].dj  }}</span></div>
                    <div class="cxjg-info"><span class="cxjg-info-l">考评成绩:</span> <span class="cxjg-info-r">{{  cxjg[0].kpcj }}</span></div>
                </div>

              </div>
              <br />
            </div>
          </div>
        </div>

        <AppFooter />
    </div>
  </template>
  
  <script scoped>
  import AppTitle from '@/components/AppTitle.vue';
  import AppFooter from '@/components/AppFooter.vue';

  // import { getNewsList } from "@/api/index.js";
//   import { getArticle } from "@/api/index.js";
  import { getMenu, GetZscx } from "@/api/index.js";


  export default {
    name: 'AppArticle',
    components: {
        AppTitle,AppFooter
    },
    data() {
      return {
        // 垂直菜单
        menuList: [],

        form: {
            name: ""
        },
        cxjg: [],
        // article_id: "",   // 具体文章的ID
        // menu_id: "",      // 分类菜单的ID 
        // menu_name: "",    // 分类菜单的名字

        // 文章
        // ArticleContent:""   //具体文章内容
      }
    },

    mounted() {
      this.backToTop();
      this.getQuery();
      this.getMenuList();
    },
    watch: {
      $route() {
        this.backToTop();
        console.log('发生改变')
        this.getQuery();
        this.getMenuList();
      },
    },
    methods: {
        // 返回顶部
        backToTop(){
            // console.log('执行')
            document.documentElement.scrollTop = 0;
        },
        // 获取路由中的 name
        getQuery() {
            let name = this.$route.query.name;
            this.form.name = name;

            if(name) {
                GetZscx({name: name}).then(r=> {
                    if(r.data.code == 1) {
                        
                        if(r.data.data.length > 0) {
                            console.log(r.data.data[0]);
                            this.cxjg.push(r.data.data[0]);
                            console.log(this.cxjg.length)
                        } else {
                            console.log('没有查到');
                            this.cxjg = [];
                        }
                    }
                })
            } else {
                console.log('没有查到');
                this.cxjg = [];
            }
        },

        // get菜单list
        getMenuList() {
            getMenu().then(res=> {
            // 过滤状态停用
            let _list = res.data.data[0].menu.filter(function(val){
            return val.menu_status === 0;
            });
            this.menuList = _list;
            })
        },
        // 路由跳转
        goToPage(sid,menu_name) {
            console.log(menu_name)
            this.$router.push({path: '/news', query: {t: Date.parse(new Date()),sid: sid,menu_name: menu_name}});
        },

        // 证书查询
        search_zs() {
            let name = this.form.name || "";
            if(name) {
                GetZscx({name: name}).then(r=> {
                    if(r.data.code == 1) {
                        
                        if(r.data.data.length > 0) {
                            console.log(r.data.data[0]);
                            this.cxjg.push(r.data.data[0]);
                            console.log(this.cxjg.length)
                        } else {
                            console.log('没有查到');
                            this.cxjg = [];
                        }
                    }
                })
            } else {
                console.log('没有查到');
                this.cxjg = [];
            }

        }
    }
  }

  </script>
  
  <style scoped>
    html,body {
        margin: 0;
        padding: 0;
    }

    .news {
        display: flex;
        flex-direction: row;
        min-height: 1300px;
        margin-top: 40px;
    }
    .news > .news-container {
        display: flex;
        flex-direction: row;
        min-height: 1300px;
        width: 1200px;
        margin: 0 auto;
    }
    .news-container > .news-L {
        display: flex;
        height: 1199px;
        width: 300px;
        /* background-color: #e5e5e5; */
    }

    /** 左侧菜单 */
    .news-L > .news-L-menu {
      width: 302px;
    }
    .news-L-menu > .news-L-menu-title {
      display: flex;
      height: 45px;
      width: 300px;
      background-color: #ffffff;
      font-size: 24px;font-weight: bold;letter-spacing: 2px;
      color: #9a0201;
    }

    .news-L-menu > .news-L-menu-list {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      width: 300px;
      background-color: #ffffff;
      font-size: 16px;
      color: #000000;
      border-bottom: 1px solid #e5e5e5;
    }
    .news-L-menu-list > .news-L-menu-list-a {
      position: absolute;
      top: 0;left: 0;
      height: 100%;width: 100%;
    }
    .news-L-menu > .news-L-menu-list:hover {
      background-color: #9a0201;
      color: #ffffff;
    }

    .news-container > .news-R {
        display: flex;
        flex-direction: column;
        min-height: 990px;
        width: 850px;margin-left: 50px;

    }
    /** 当前位置抬头 */
    .news-R > .news-R-title {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 850px;
        padding: 3px 0 0 0;
        height: 50px;
        background-color: #ffffff;
        border-bottom: 1px solid #9a0201;
    }
    .news-R > .news-R-title a:hover{
        color: #9a0201;
    }
    /** 新闻列表List */
    .news-R > .news-R-content { 
        min-height: 885px;
        width: 850px;

    }
    /** 查询条件 */
    .news-R-content > .zscx-view {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 20px;
        height: 80px;
        width: 500px;
   
    }
    .zscx-view > .zscx-view-1 {
        display: flex;
        align-items: flex-start;
        height: 70px;
        width: 110px;
        font-size: 20px;font-weight: bold;
    }
    .zscx-view > .zscx-view-2 {
        display: flex;
        align-items: flex-start;
        height: 70px;
    }
    .zscx-view > .zscx-view-2 input {
        height: 24px;
    }
    .zscx-view > .zscx-view-3 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 70px;
        margin-left: 10px;
        font-size: 16px;
        background-color: #9a0201;
        border-radius: 4px;
        letter-spacing: 2px;
        color: #ffffff;
    }

    /** 查询结果 */
    .news-R-content > .zscx-cxjg {
        display: flex;
        flex-direction: column;
        height: 360px;
        width: 100%;
        background-color: #E1EEC3;
        border-top: 1px dashed #9a0201;
        border-bottom: 1px dashed #9a0201;
    }
    .zscx-cxjg > .cxjg-info {
        display: flex;
        flex-direction: row;
        height: 26px;
        padding: 2px 0;
        font-size: 15px;
        text-indent: 12px;
    }
    .cxjg-info > .cxjg-info-l {
        width: 120px;
    }
    .cxjg-info > .cxjg-info-r {
        
    }
  </style>

