<!-- 页脚区域 -->
<template>

    <!-- 页脚 -->
    <div class="footer">
        <div class="footer-container">
            <div class="footer-L">
            <div class="footer-L-logo">
                <img src="../assets/image/logo-2.jpg" alt="" srcset="">
            </div>
            </div>
            <div class="footer-R">
            <div class="footer-R-list">
                <span>CopyRight © 2024 全国课本剧合作办学平台 版权所有</span>
                <span>地址：上海市宝山区殷高西路518号1209-1211    联系方式:：18019210201(张老师)</span>
            </div>
            </div>
        </div>
    </div>
  
</template>
<script>
export default {
    name: 'AppFooter',
    props: {
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>
  
<style>
  /**
    *  footer
    */
.footer {

    display: flex;
    height: 159px;
    width: 100%;
    background-color: #450102;
}
.footer > .footer-container {
    display: flex;

    height: 159px;
    width: 1200px;
    margin: 0 auto;
}
.footer-L {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 159px;
    width: 600px;
}
.footer-R {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 159px;
    width: 600px;

}

.footer-L > .footer-L-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-size: 12px;
    letter-spacing: 2px;
    height: 88px;
    width: 124px;
    background-color: #e5e5e5;
    border-radius: 12px;
}
.footer-L > .footer-L-logo img {
    height: 88px;
    width: 124px;
}

.footer-R > .footer-R-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 88px;
    width: 100%;
    color:#ffffff;
    font-size: 14px;
}
.footer-R > .footer-R-list span {
    padding: 5px 0;
}
  
</style>