<template>
  <!-- <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->
  <div id="app">
    <AppTitle />
    <AppHome />
    <AppFooter />

  </div>
 
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import AppTitle from '@/components/AppTitle.vue';
import AppHome from '@/components/AppHome.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    AppTitle,
    AppHome,
    AppFooter
  },
  data() {
    return{

    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style>

  html,body {
    margin: 0;
    padding: 0;
  }

  /**
    * 分割
    */
  /* .blank {
    height: 40px;
    width: 100%;
  } */

</style>