
import axios from 'axios' 

// 创建axios实例
const request = axios.create({
    // 这里可以放一下公用属性等。
    baseURL: '', // 用于配置请求接口公用部分，请求时会自动拼接在你定义的url前面。
    withCredentials: false, // 跨域请求时是否需要访问凭证
    timeout: 3 * 1000, // 请求超时时间
 
})
 
// 请求拦截器
request.interceptors.request.use((config) => {

    return config;
}, (error) => {
    console.log(error)

    // return Promise.reject(error)
})
 
request.interceptors.response.use((response) => {
//返回码以自己的为定，如果没有安装elementui就换成自己的提示
    let { code, msg } = response.data
    if (code != 200) {
        // Message({ message: msg || 'error', type: 'warning' })
        console.log(msg)
    }
    return response;
}, (error) => {
    console.log(error)
    // return Promise.reject(error)
})
 
export default request;//记得暴露出去
