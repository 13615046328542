import request from "@/utils/request";

// const baseUrl = "http://8.217.157.86:9093/api";
const baseUrl = "https://service.shkbj.cn:9093/api";
// const baseUrl = "http://127.0.0.1:9093/api";

// 查出所有菜单
export function getMenu(params= {}) {
    return request({
        method: 'get',
        url: baseUrl + '/information',
        params 
    })
}

// 根据传入的menu_id查出新闻列
export function getSidNewsList(params = {}) {
    return request({
        method: 'get',
        url: baseUrl + '/information/newslist',
        params
    })
}

// 根据传入的具体id查到文章
export function getArticle(params = {}) {
    return request({
        method: 'get',
        url: baseUrl + '/information/article',
        params
    })
}


// 新闻资讯 新闻列表
export function getNewsList(params= {}) {
    return request({
        method: 'get',
        url: baseUrl + '/information/newsinfo',
        params 
    })
}

// 新闻资讯 具体新闻
export function getNewsInfo(params= {}) {
    return request({
        method: 'get',
        url: baseUrl + '/information/newsinfo/news',
        params 
    })
}

// 查看平台简介 具体文章
export function getPtjj(params= {}) {
    return request({
        method: 'get',
        url: baseUrl + '/information/centerinfo',
        params 
    })
}

// 获取老师信息 getMstd
export function getMstd(params= {}) {
    return request({
        method: 'get',
        url: baseUrl + '/information/mstd',
        params 
    })
}

// 获取广电资格考试list 
export function getGdList(params= {}) {
    return request({
        method: 'get',
        url: baseUrl + '/information/gdlist',
        params 
    })
}

export function GetKcjjList(params= {}) {
    return request({
        method: 'get',
        url: baseUrl + '/information/kclist',
        params 
    })
}

export function GetZscx(params= {}) {
    return request({
        method: 'get',
        url: baseUrl + '/information/zscx',
        params 
    })
}

// POST
// export function login(data) {
//     return request({
//         method: 'post',
//         url: baseUrl + '/business/login',
//         data: data //可以简写为data
//     })
// }