// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AppNewslist from '../views/AppNewslist.vue';
import AppArticle from '../views/AppArticle.vue';
import AppZscx from '../views/AppZscx.vue';
// import NotFound from '../views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/news',
    name: 'news',
    component: AppNewslist
  },
  {
    path: '/article',
    name: 'article',
    component: AppArticle
  },
  {
    path: '/zscx',
    name: 'zscx',
    component: AppZscx    //证书查询
  },
  // 缺省
  {
    path: '/:catchAll(.*)',       // 匹配不到路由,跳转404页面
    name: 'not-found',
    component: HomeView
  }
]

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes
// })

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
