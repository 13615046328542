<!-- 主页 内容区域 -->
<template>
<div class="content-shell">
  <!-- 内容主体 通知公告、考级资讯 -->
  <div class="content">
    <div class="content-information">
      <!-- 通知公告 -->
      <div class="information-L">
        <!-- 通知公告抬头 -->
        <div class="information-L-title" style="position: relative;">
          <a href="javascript:;" style="position: absolute;top: 0;left: 0;height: 100%;width: 55%;"></a>
          <div style="color:#9A0201; font-size: 22px;font-weight: bold;letter-spacing: 1px;">新闻资讯</div>
          <span style="color: #9e9e9e;font-size: 14px;margin: 6px 0 0 10px;">News Information</span>
          <img src="../assets/image/icon/right.jpg" style="height: 13px;width: 13px;margin-top: 9px;margin-left: 4px;" alt="">

          <!-- <img src="../assets/image/icon/tzgg.jpg" style="height: 24px;width: 245px;" alt="">
          <a href="javascript:;">
              <img src="../assets/image/icon/right.jpg" style="height: 13px;width: 13px;margin-top: 13px;margin-left: 4px;" alt="">
          </a> -->
        </div>
        <!-- 通知公告新闻列表 -->
        <div class="information-L-content">
          <ul class="information-L-content-ul">
              <li v-for="(item,index) in noticelist" :key="index">
                <span>
                    <div class="information-L-content-ul-font">{{ item.date.substring(8, 10) }}</div>
                    {{ item.date.substring(0, 7) }}
                </span>
                <a href="javascript:;" @click="goToNews(item._id,item.menu_id,item.menu_name)">
                    {{ item.info }}
                </a>
              </li>
          </ul>
          <!-- 预留区域 -->
          <div class="information-L-content-ylqy">
            <video :src="videoURL" width="460" height="350" style="max-width: 100%;" allowfullscreen="false" controls="controls" controlslist="nodownload">
						</video>
            <!-- <iframe frameborder="0" src="https://v.qq.com/txp/iframe/player.html?vid=s0879ed9ulf" allowfullscreen="true" width="460" height="350">

            </iframe> -->
          </div>
        </div>
      </div>
      <!-- 考级资讯 -->
      <div class="information-R">
        <!-- 考级资讯抬头 -->
        <div class="information-R-title" style="position: relative;">
          <a href="javascript:;" style="position: absolute;top: 0;left: 0;height: 100%;width: 55%;"></a>
          <div style="color:#9A0201; font-size: 22px;font-weight: bold;letter-spacing: 1px;">考级资讯</div>
          <span style="color: #9e9e9e;font-size: 14px;margin: 6px 0 0 10px;">Grading Information</span>
          <img src="../assets/image/icon/right.jpg" style="height: 13px;width: 13px;margin-top: 9px;margin-left: 4px;" alt="">
          <!-- <img src="../assets/image/icon/kjzx.jpg" style="height: 26px;width: 230px;" alt="">
          <a href="javascript:;">
              <img src="../assets/image/icon/right.jpg" style="height: 13px;width: 13px;margin-top: 13px;margin-left: 4px;" alt="">
          </a> -->
        </div>
        <!-- 证书列表 -->
        <div class="information-R-content">
          <div class="zs-list">
            <ul>
              <li v-for="(item,index) in booklist" :key="index">
                  <img :src="item.url" alt="" />
                  <span>{{ item.name }}</span>
              </li>
            </ul>
          </div>
        <!-- 考级资讯下的新闻列表 -->
        <div class="kjzx-list">
          <ul class="kjzx-list-ul">
            <li v-for="(item,index) in kjzxlist" :key="index">
                <a href="javascript:;" @click="goToNews(item._id,item.menu_id,item.menu_name)">{{ item.info }}</a>
                {{ item.date.substring(0, 10) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- 活动资讯 -->
  <div class="hdzx">
    <div class="hdzx-main">
      <!-- 活动资讯抬头 -->
      <div class="hdzx-main-title" style="position: relative;">
        <a href="javascript:;" style="position: absolute;top: 0;left: 0;height: 100%;width: 280px"></a>
        <div style="color:#9A0201; font-size: 22px;font-weight: bold;letter-spacing: 1px;">活动资讯</div>
        <span style="color: #9e9e9e;font-size: 14px;margin: 6px 0 0 10px;">Activity Information</span>
        <img src="../assets/image/icon/right.jpg" style="height: 13px;width: 13px;margin-top: 9px;margin-left: 4px;" alt="">
        <!-- <img src="../assets/image/icon/hdzx.jpg" style="height: 24px;width: 225px;" alt="">
        <a href="javascript:;">
            <img src="../assets/image/icon/right.jpg" style="height: 13px;width: 13px;margin-top: 13px;" alt="">
        </a> -->
      </div>
      <!-- 活动资讯内容 -->
      <div class="hdzx-main-content">
        <div class="main-content-card" v-for="(item,index) in hdzxlist" :key="index">
          <div class="main-content-card-img">
            <img :src="item.url" alt="">
          </div>
          <a href="javascript:;" class="main-content-card-a" @click="goToNews(item._id,item.menu_id,item.menu_name)">{{ item.info }}</a>
        </div>


      </div>
    </div>
  </div>

  <!-- 课间风采 抬头 -->
  <div class="kjfc">
    <div class="kjfc-main">
      <div class="kjfc-main-title">
        <a href="javascript:;" style="position: absolute;top: 0;left: 0;height: 100%;width: 200px;"></a>
        <div style="color:#9A0201; font-size: 22px;font-weight: bold;letter-spacing: 1px;">课堂风采</div>
        <span style="color: #9e9e9e;font-size: 14px;margin: 6px 0 0 10px;">Class Performance</span>
        <!-- <img src="../assets/image/icon/yqlj.jpg" style="height: 26px;width: 205px;" alt=""> -->
      </div>
    </div>
  </div>
  <!-- 课间风采 内容 -->
  <div class="kjfc-content">
    <div class="kjfc-content-shell">

      <div class="kjfc-content-shell-L">
        <ul class="kjfc-content-shell-L-ul">
          <li class="kjfc-content-shell-L-li" v-for="(item,index) in imgList" :key="index">
            <a href="javascript:;"></a>
            <img :src="item.url" alt="" class="kjfc-content-shell-L-li-img">
          </li>
        </ul>
      </div>  

      <div class="kjfc-content-shell-R">
        <ul class="kjfc-content-shell-R-ul">
          <li class="kjfc-content-shell-R-li">

            <div class="shell-R-li-book">
              <div class="shell-R-li-book-L">
                <img src="../assets/image/kjfc/book1.jpg" alt="">
              </div>
              <div class="shell-R-li-book-R">
                <div>《朗诵艺术考级教程》</div>
                <div>朗诵艺术考级教程</div>
              </div>
            </div>

            <div class="shell-R-li-book">
              <div class="shell-R-li-book-L">
                <img src="../assets/image/kjfc/book2.jpg" alt="">
              </div>
              <div class="shell-R-li-book-R">
                <div>《少年儿童播音主持艺术考级教程》</div>
                <div>少年儿童播音主持艺术考级教程</div>
              </div>
            </div>

            <div class="shell-R-li-book">
              <div class="shell-R-li-book-L">
                <img src="../assets/image/kjfc/book3.jpg" alt="">
              </div>
              <div class="shell-R-li-book-R">
                <div>《少年儿童播音主持艺术考级教程（有声版）学生训练用书》</div>
                <div>少年儿童播音主持艺术考级教程（有声版）学生训练用书</div>
              </div>
            </div>

          </li>

        </ul>
      </div>

    </div>
  </div>

  <!-- 友情链接 抬头-->
  <div class="yqlj">
    <div class="yqlj-main">
      <div class="yqlj-main-title">
        <a href="javascript:;" style="position: absolute;top: 0;left: 0;height: 100%;width: 200px;"></a>
        <div style="color:#9A0201; font-size: 22px;font-weight: bold;letter-spacing: 1px;">友情链接</div>
        <span style="color: #9e9e9e;font-size: 14px;margin: 6px 0 0 10px;">Friendship Link</span>
        <!-- <img src="../assets/image/icon/yqlj.jpg" style="height: 26px;width: 205px;" alt=""> -->
      </div>
    </div>
  </div>

  <!-- 友情连接 内容 -->
  <div class="yqlj-content">
      <div class="yqlj-content-shell">
        <div class="yqlj-content-shell-a">
          <a href="javascript:;">中国文化市场网</a>
          <a href="javascript:;">全国社会艺术水平考级网站</a>
          <a href="javascript:;">中华人民共和国文化和旅游部</a>
          <a href="javascript:;">北京市文化和旅游局</a>
        </div>
      </div>
    </div>
  </div>
  
</div>

</template>
<script>


import { getSidNewsList } from "@/api/index.js";
export default {
    name: 'AppHome',
    props: {
    },
    data() {
        return {
            videoURL: require("../assets/image/index.mp4"),
            booklist: [         // 考级资讯里的书本list
                {id: 1, url: require("../assets/image/book/1.jpg"), name: "朗诵教师"},
                {id: 2, url: require("../assets/image/book/2.jpg"), name: "少儿播音主持教师"},
                {id: 3, url: require("../assets/image/book/3.jpg"), name: "课本剧教师"},
                {id: 4, url: require("../assets/image/book/4.jpg"), name: "朗诵考级"},
                {id: 5, url: require("../assets/image/book/5.jpg"), name: "少儿播音主持考级"},
                {id: 6, url: require("../assets/image/book/6.jpg"), name: "课本剧考级"},
            ],
            kjzxlist: [         // 考级咨询里的新闻list
                // {id: 1,info: "演示文本演示文本演示文本演示文本演示文本演示文本 长文本长文本长文本长文本长文本长文本长文本长文本长文本长文本长文本长文本",date:"2024-01-03"},
                // {id: 2,info: "演示文本",date:"2024-01-02"},
                // {id: 2,info: "演示文本",date:"2024-01-01"},
            ],
            noticelist: [       // 通知公告列表
                // {id: 1,date: "2024/1",day: "11",info: "演示文本"},
                // {id: 2,date: "2024/1",day: "10",info: "演示文本"},
                // {id: 3,date: "2024/1",day: "9",info: "演示文本"}
            ],
            hdzxlist: [],
            imgList: [
              {id: 1,url: require("../assets/image/kjfc/1.jpg")},
              {id: 2,url: require("../assets/image/kjfc/2.jpg")},
              {id: 3,url: require("../assets/image/kjfc/3.jpg")},
              {id: 4,url: require("../assets/image/kjfc/4.jpg")},
              {id: 5,url: require("../assets/image/kjfc/5.jpg")},
              {id: 6,url: require("../assets/image/kjfc/6.jpg")}
            ]
        }
    },
    mounted() {
      this.getNews();
      this.getKjzx();
      this.getHdzx();
    },
    methods: {

      // 查看具体新闻
      goToNews(article_id,menu_id,menu_name) {
        console.log(article_id, menu_id, menu_name);
        // console.log(sid);
        if(article_id || menu_id || menu_name) {
          console.log('click',article_id,menu_id)
          this.$router.push({path: '/article', query: {article_id: article_id,menu_id: menu_id,menu_name: menu_name}});
        } else {
          console.log('没有有效id')
        }
      },

      // 新闻列
      getNews(){
        getSidNewsList({menu_id: "658ff7b859bca5344528a01e",page: 1,pageSize: 10}).then(res=> {
          console.log('新闻:',res.data.data.rows);
          let ref = res.data.data.rows;
          for(let i = 0; i < ref.length; i++) {
            if(i >= 3) {
              return false;
            }
            this.noticelist.push({_id: ref[i]._id,menu_id: ref[i].menu_id,menu_name: ref[i].menu_name,info: ref[i].title, date: ref[i].createdAt,url: ref[i].fontcover_img_url});
          }
        })
      },

      // 考级资讯
      getKjzx() {
        getSidNewsList({menu_id: "65a6e44fb8f42c13f1081964",page: 1,pageSize: 10}).then(res=> {
          console.log('新闻:',res.data.data.rows);
          let ref = res.data.data.rows;
          for(let i = 0; i < ref.length; i++) {
            if(i >= 3) {
              return false;
            }
            this.kjzxlist.push({_id: ref[i]._id,menu_id: ref[i].menu_id,menu_name: ref[i].menu_name,info: ref[i].title, date: ref[i].createdAt,url: ref[i].fontcover_img_url});
          }
          console.log(this.noticelist)
        })
      },

      // 活动资讯
      getHdzx() {
        getSidNewsList({menu_id: "65a6e455b8f42c13f1081967",page: 1,pageSize: 10}).then(res=> {
          console.log('新闻:',res.data.data.rows);
          let ref = res.data.data.rows;
          for(let i = 0; i < ref.length; i++) {
            if(i >= 5) {
              return false;
            }
            this.hdzxlist.push({_id: ref[i]._id,menu_id: ref[i].menu_id,menu_name: ref[i].menu_name,info: ref[i].title, date: ref[i].createdAt,url: ref[i].fontcover_img_url});
          }
          console.log(this.noticelist)
        })
      },


    }
}
</script>
  
<style>
  .content-shell {
    /* height: 1197px; */
    width: 100%;
  }
  /**
    * 内容区域
    */
  .content {
    margin-top: 40px;
    /* height: 663px; */
    display: flex;
    flex-direction: column;
  }

  .content-information {
    height: 663px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  .content-information > .information-L {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: 663px;
    width: 460px;
  }
  .information-L-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    width: 460px;
  }

  .information-L-content {
    display: flex;
    flex-direction: column;
    height: 588px;
    width: 460px;
    border-top:solid 1px #9A0201;
  }

  .content-information > .information-R {
    display: flex;
    flex-direction: column;

    width: 636px;
    margin-left: 105px;

  }
  .information-R-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    width: 460px;
  }

  .information-R-content {
    display: flex;
    flex-direction: column;
    height: 613px;
    width: 636px;
    /* border-top:solid 1px #9A0201; */
  }


  /** 左边 通知公告 */
  ul,li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    /* border: 0px; */

  }
  /* ul li+li:before {
    
  } */
  .information-L-content-ul {
    height: 231px;
    width: 460px;
  }
  .information-L-content-ul li {
    height: 54px;
    line-height: 20px;
    font-size: 14px;
    padding: 15px 0 8px 0;
    border-bottom: 1px dashed #e5e5e5;
  }
  
  .information-L-content-ul li span {
    height: 44px;
    width: 54px;
    background-color: #9A0201;
    line-height: 16px;
    padding: 5px 0;
    display: block;
    float: left;
    font-size: 12px;
    text-align: center;
    color: #FFF;
  }
  .information-L-content-ul li a:hover {

    color: #9A0201;

  }
  /* .information-L-content-ul li span font{
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  } */
  .information-L-content-ul-font{
    font-size: 16px;
    font-weight: bold;
    /* line-height: 24px; */
    margin: 2px 0 8px 0;padding: 0;
  }
  
  .information-L-content-ul li a {
      padding: 5px 0 0 10px;
      display: block;
      float: left;
      width: 396px;
      /** 限制行数 */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
  }

  a {
      text-decoration: none;
      color: #333;
  }

  /** 左边 通知公告 预留区域 */
  .information-L-content > .information-L-content-ylqy {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    color: #ffffff;
    font-size: 18px;
    height: 350px;
    width: 460px;
    background-color: #9c5f5f;
  }
  /** 右边 考级资讯 */
  .information-R-content > .zs-list {
    height: 452px;
    width: 636px;
    border-bottom: 1px dashed #e5e5e5;
  }
  .zs-list ul li {
    float: left;
    width: 200px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    height: 220px;
    
  }
  .zs-list ul li img {
    width: 130px;
    border-radius: 2px;
  }
  .zs-list ul li span {
    padding: 0px 0;
    display: block;
    text-align: center;
    color: #AB1D1F;
  }

  .kjzx-list-ul li {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: right;
    border-bottom: 1px dashed #e5e5e5;
  }
  .kjzx-list-ul li a {

    text-align: left;
    width: 540px;
    float: left;
    
    /** 限制行数 */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;

  }
  .kjzx-list-ul li a:hover {
    color: #9A0201;
  }

  /**
    *  活动资讯
    */
  .hdzx {
    height: 300px;
    margin-top: 40px;
    background-color: #f0f0f0;
  }
  .hdzx > .hdzx-main {
    height: 300px;
    width: 1200px;
    margin: 0 auto;
    
  }
  .hdzx-main > .hdzx-main-title {
    display: flex;flex-direction: row;
    align-items: center;
    height: 50px;

  }
  .hdzx-main > .hdzx-main-content {
    display: flex;flex-direction: row;
    height: 250px;
    width: 1200px;

  }
  /** 资讯的列表 */
  .hdzx-main-content > .main-content-card {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    height: 200px;
    width: 212px;

  }
  .hdzx-main-content > .main-content-card:nth-child(1) {
    margin-right: 34px;
  }
  .hdzx-main-content > .main-content-card:nth-child(2) {
    margin-right: 34px;
  }
  .hdzx-main-content > .main-content-card:nth-child(3) {
    margin-right: 34px;
  }
  .hdzx-main-content > .main-content-card:nth-child(4) {
    margin-right: 34px;
  }

  /** 资讯列表的详情卡片 */
  .main-content-card > .main-content-card-img {
    display: flex;
    flex-direction: row;
    height: 150px;
    width: 212px;
    background-color: #da9f9f;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    letter-spacing: 2px;
    font-size: 18px;font-weight: bold;
  }
  .main-content-card > .main-content-card-img img {
    height: 150px;
    width: 212px;
    border: 1px solid #9A0201;
    box-shadow: 2px 2px 4px rgb(56, 0, 0);
  }
  .main-content-card > .main-content-card-a {
    margin-top: 10px;
    height: 35px;
    width: 212px;
    font-size: 12px;
    color: #000000;
    /** 限制行数 */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .main-content-card > .main-content-card-a:hover {
    color: #9A0201;
  }
  /**
    *  课间风采 抬头
    */
  .kjfc {
    margin-top: 40px;
    height: 50px;

  }

  .kjfc > .kjfc-main {
    height: 50px;
    width: 1200px;
    margin: 0 auto;

  } 
  .kjfc-main > .kjfc-main-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    position: relative;
  } 

  /** 课间风采 内容 */
  .kjfc-content {
    height: 610px;

  }
  .kjfc-content > .kjfc-content-shell {
    display: flex;
    flex-direction: row;
    height: 640px;
    width: 1200px;
    margin: 0 auto;
    border-top: 1px solid #9A0201;
  
  }

  .kjfc-content-shell > .kjfc-content-shell-L {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    height: 610px;
    width: 600px;
    
  }

  .kjfc-content-shell-L > .kjfc-content-shell-L-ul {
    display: flex;
    flex-wrap: wrap;
    height: 610px;
    width: 600px;
   
  }

  .kjfc-content-shell-L-ul > .kjfc-content-shell-L-li {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 190px;
    width: 290px;
    margin-right: 10px;
    overflow: hidden;

  }

  .kjfc-content-shell-L-li > .kjfc-content-shell-L-li-img{
    height: 190px;
    width: 290px;
    
  }

  .kjfc-content-shell-L-ul > .kjfc-content-shell-L-li a {
    position: absolute;
    top: 0;left: 0;
    height: 100%;
    width: 100%;
  }

  .kjfc-content-shell > .kjfc-content-shell-R {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    height: 610px;
    width: 600px;
    
  }

  .kjfc-content-shell-R > .kjfc-content-shell-R-ul {
    display: flex;
    flex-direction: column;
    height: 610px;
    width: 600px;
    
  }

  .kjfc-content-shell-R-ul > .kjfc-content-shell-R-li {
    display: flex;
    flex-direction: column;
    height: 610px;
    width: 550px;
    margin-left: 50px;
    margin-bottom: 10px;

  }

  .kjfc-content-shell-R-li > .shell-R-li-book {
    display: flex;
    flex-direction: row;
    height: 190px;
    width: 550px;
    background-color: #dddddd;
    margin-bottom: 10px;
  }

  .shell-R-li-book > .shell-R-li-book-L {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 190px;
    width: 150px;

  } 
  /** 书本图片 */
  .shell-R-li-book > .shell-R-li-book-L img {

    height: 150px;
    width: 120px;
  }

  .shell-R-li-book > .shell-R-li-book-R {
    margin: 20px 10px;
    height: 150px;
    width: 380px;
    font-size: 14px;

  }

  /**
    *  友情链接
    */
    /** 抬头 */
  .yqlj {
    margin-top: 40px;
    height: 50px;

  }

  .yqlj > .yqlj-main {
    height: 50px;
    width: 1200px;
    margin: 0 auto;

  } 
  .yqlj-main > .yqlj-main-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    position: relative;
  } 
  /** 友情连接内容 */
  .yqlj-content {
    height: 100px;
    background-color: #eee;
  }
  .yqlj-content > .yqlj-content-shell {
    height: 100px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .yqlj-content-shell > .yqlj-content-shell-a {
    height: 28px;
    font-size: 14px;
  }
  .yqlj-content-shell > .yqlj-content-shell-a a {
    padding-right: 30px;
  }

  .yqlj-content-shell > .yqlj-content-shell-a a:hover {
    text-decoration: none;
    color: #9A0201;
  }

</style>