<!--  文章  list 页 -->
<template>
    <div id="app">
        <AppTitle />
        <!-- 新闻页 内容区域 -->
        <div class="news">
          <div class="news-container">
            <!-- 左侧 -->
            <div class="news-L">
              <!-- 竖向菜单 -->
              <div class="news-L-menu">
                  <div class="news-L-menu-title">
                    信息分类
                  </div>  
                  <div class="news-L-menu-list" v-for="(item,index) in menuList" :key="index">
                    <a href="javascript:;" class="news-L-menu-list-a" @click="goToPage(item._id,item.menu_name)"></a>
                    {{ item.menu_name }}
                  </div>
                </div>
              </div>
            <!-- 内容 -->
            <div class="news-R">
              <!-- 内容 抬头 显示所在URL -->
              <div class="news-R-title">
                当前位置：<a href="/">网站首页</a> > {{ this.menu_name }}
              </div>
              <!-- 新闻列表list -->
              <div class="news-R-content">
                <ul class="news-R-content-ul">
                  <li class="news-R-content-li" v-for="(item,index) in newsList" :key="index">
                    <div class="news-R-content-li-L">
                      <span class="news-R-content-li-L-day">
                        {{ item.createdAt.substring(8, 10)}}
                      </span>
                      <span class="news-R-content-li-L-date">
                        {{ item.createdAt.substring(0, 7)}}
                      </span>
                    </div>
                    <div class="news-R-content-li-R">
                      <a href="javascript:;" @click="goToNews(item._id,item.menu_id,item.menu_name)">
                        {{ item.title }}
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <el-pagination background v-model:page-size="pageSize" v-model:current-page="page" @prev-click="onReduce" @next-click="onAdd" layout="prev, pager, next" :total="countNumber" />

            </div>
          </div>
            
        </div>

        <AppFooter />
    </div>
  </template>
  
  <script scoped>
  import AppTitle from '@/components/AppTitle.vue';
  import AppFooter from '@/components/AppFooter.vue';

  // import { getNewsList } from "@/api/index.js";
  import { getSidNewsList } from "@/api/index.js"; // 根据传入sid查出新闻列
  import { getMenu } from "@/api/index.js";

  export default {
    name: 'AppXwzx',
    components: {
        AppTitle,AppFooter
    },
    data() {
      return {
        // 垂直菜单
        menuList: [],

        sid: "",          // 分类菜单的ID
        menu_name: "",    // 分类菜单的名字
        // str: "",
        // title: ""

        // 新闻列List
        newsList: [

        ],

        // 分页用
        list: [],
        page: 1,
        pageSize: 10,
        countPage: 0,
        countNumber: 0,
      }
    },

    mounted() {
      this.backToTop();
      this.getQuery();
      this.getArticleList();
      this.getMenuList();
      // console.log(this.page)
    },
    watch: {
      $route() {
        this.backToTop();
        console.log('发生改变')
        this.getQuery();
        this.getMenuList();
      },
      sid() {
        console.log('menu_id发生变化')
        this.getArticleList(this.sid);
      },
      page() {
        console.log(this.page);
        this.getInfo();
      }
    },
    methods: {
      // 返回顶部
      backToTop(){
        // console.log('执行')
        document.documentElement.scrollTop = 0;
      },

      // 获取路由中的menu_name
      getQuery() {
        let sid = this.$route.query.sid;
        let menu_name = this.$route.query.menu_name;

        console.log("新闻列表的生命周期内:",sid,menu_name)
        this.sid = sid;
        this.menu_name = menu_name;

      },

      // 获取远端新闻列
      getArticleList(menu_id) {
        if(!menu_id) {
          console.log('路由错误')
          return false;
        }
        this.getInfo();
      },
      // 返回首页 分页
      // onBegin() {
      //   if(this.page <= 1) {
      //     return false;
      //   }
      //   this.page = 1;
      //   this.getInfo();
      // },
      // 上一页 分页
      onReduce () {
        // if(this.page <= 1) {
        //   return false;
        // }
        // this.page = (this.page - 1);
        this.getInfo();
      },
      // 下一页 分页
      onAdd () {
        // if(this.page == this.countPage) {
        //   return false;
        // }
        console.log('this.page',this.page)
        // this.page = (this.page + 1);
        this.getInfo();
      },
      // 最后一页 分页 
      // onFinal() {
      //   if(this.page == this.countPage) {
      //     return false;
      //   }
      //   this.page = this.countPage;
      //   this.getInfo();
      // },

      getInfo() {
        getSidNewsList({page: this.page,pageSize:this.pageSize,menu_id: this.sid}).then(res => {
          if(res.data.code == 1) {
            this.countNumber = res.data.data.total;
            console.log('文章总数',res.data.data.total)
            if(res.data.data.total == 1) {  // 如果文章只有一篇 直接进入详情

              console.log(res.data.data);
              console.log(res.data.data.rows[0])

              this.$router.push({path: '/article', query: {article_id: res.data.data.rows[0]._id,menu_id: res.data.data.rows[0].menu_id,menu_name: res.data.data.rows[0].menu_name}});

              return false;
            } else {
              this.newsList = res.data.data.rows;
          
              let _countPage = (res.data.data.total + this.pageSize - 1) / this.pageSize;
              this.countPage = parseInt(_countPage);
              console.log(res.data.data.rows);
            }
          }
        })
      },
      // getInfo() {
      //   getNewsList({page: this.page,pageSize:this.pageSize}).then(res => {
      //     if(res.data.code == 1) {
      //       this.list = res.data.data.rows;
      //       let _countPage = (res.data.data.total + this.pageSize - 1) / this.pageSize;
      //       this.countPage = parseInt(_countPage);
      //     } else {
      //       console.log('err');
      //     }
      //   })

      // },
      // 查看具体新闻
      goToNews(article_id,menu_id,menu_name) {
        // console.log(sid);
        if(article_id || menu_id || menu_name) {
          console.log('click',article_id,menu_id)
          this.$router.push({path: '/article', query: {article_id: article_id,menu_id: menu_id,menu_name: menu_name}});
        } else {
          console.log('没有有效id')
        }
      },
      // get菜单list
      getMenuList() {
        getMenu().then(res=> {
        // 过滤状态停用
        let _list = res.data.data[0].menu.filter(function(val){
          return val.menu_status === 0;
        });
        this.menuList = _list;
        })
      },
      // 路由跳转
      goToPage(sid,menu_name) {
        console.log(menu_name)
        this.$router.push({path: '/news', query: {t: Date.parse(new Date()),sid: sid,menu_name: menu_name}});
      },
    }
  }

  </script>
  
  <style scoped>
    html,body {
      margin: 0;
      padding: 0;
    }

    .news {
      display: flex;
      flex-direction: row;
      height: 1300px;
      margin-top: 40px;
    }
    .news > .news-container {
      display: flex;
      flex-direction: row;
      height: 1300px;
      width: 1200px;
      margin: 0 auto;
    }
    .news-container > .news-L {
        display: flex;
        height: 1199px;
        width: 300px;

    }
    /** 左侧菜单 */
    .news-L > .news-L-menu {
      width: 302px;
    }
    .news-L-menu > .news-L-menu-title {
      display: flex;
      height: 45px;
      width: 300px;
      background-color: #ffffff;
      font-size: 24px;font-weight: bold;letter-spacing: 2px;
      color: #9a0201;
    }

    .news-L-menu > .news-L-menu-list {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      width: 300px;
      background-color: #ffffff;
      font-size: 16px;
      color: #000000;
      border-bottom: 1px solid #e5e5e5;
    }
    .news-L-menu-list > .news-L-menu-list-a {
      position: absolute;
      top: 0;left: 0;
      height: 100%;width: 100%;
    }
    .news-L-menu > .news-L-menu-list:hover {
      background-color: #9a0201;
      color: #ffffff;
    }

    .news-container > .news-R {
        display: flex;
        flex-direction: column;
        height: 990px;
        width: 850px;margin-left: 50px;

    }
    /** 当前位置抬头 */
    .news-R > .news-R-title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 850px;
      padding: 8px 0 22px 0;
      height: 55px;
      background-color: #ffffff;
      border-bottom: 1px solid #9a0201;
    }
    .news-R > .news-R-title a:hover{
      color: #9a0201;
    }
    /** 新闻列表List */
    .news-R > .news-R-content { 
      height: 885px;
      width: 850px;
      margin-top: 20px;
    }
    .news-R-content > .news-R-content-ul {
      height: 100%;
      width: 100%;
      
      
    }
    .news-R-content-ul > .news-R-content-li {
      display: flex;
      flex-direction: row;
      height: 70px;
      margin: 8px 0;
      width: 850px;
      /* background-color: #f1a6a6; */
      border-bottom: 1px dashed #e5e5e5;
    }
    .news-R-content-li > .news-R-content-li-L {
      display: flex;
      flex-direction: column;
      margin: 7px;
      height: 55px;
      width: 55px;
      background-color:#9a0201;
      box-shadow: 2px 2px 4px #4e0000;
      border-radius: 4px;
      overflow: hidden;
    }
    /** span日期方块 */
    .news-R-content-li-L > .news-R-content-li-L-date {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;

      height: 25px;
      width: 100%;
      font-size: 11px;
      font-weight: bold;
      color: #ffffff;
    }
    .news-R-content-li-L > .news-R-content-li-L-day {
      display: flex;
      flex-direction: row;
      justify-content: center;

      height: 18px;margin-top: 2px;
      width: 100%;
      font-size: 17px;
      color: #ffffff;
    }
    /** 列表内的内容主题 */
    .news-R-content-li > .news-R-content-li-R {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 55px;
      margin: 7px 0 7px 10px;
      width: 760px;
    }
    .news-R-content-li > .news-R-content-li-R a{
      /** 限制行数 */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .news-R-content-li > .news-R-content-li-R a:hover {
      color: #e60101;
    }

   
    
  </style>

  <style>
    /** 分页组件 */
    .el-pagination.is-background .el-pager li.is-active {
      background-color: #9a0201 !important;
    }
    /* .el-pager li:hover {
      color: #9a0201 !important;
    } */
  </style>
