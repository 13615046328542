<!--  文章  具体内容页 页 -->
<template>
    <div id="app">
        <AppTitle />
        <!-- 新闻页 内容区域 -->
        <div class="news">
          <div class="news-container">
            <!-- 左侧 -->
            <div class="news-L">
              <!-- 竖向菜单 -->
              <div class="news-L-menu">
                <div class="news-L-menu-title">
                  信息分类
                </div>  
                <div class="news-L-menu-list" v-for="(item,index) in menuList" :key="index">
                  <a href="javascript:;" class="news-L-menu-list-a" @click="goToPage(item._id,item.menu_name)"></a>
                  {{ item.menu_name }}
                </div>
              </div>

            </div>
            <!-- 内容 -->
            <div class="news-R">
              <!-- 内容 抬头 显示所在URL -->
              <div class="news-R-title">
                当前位置：<a href="/">网站首页</a> > {{ this.menu_name }}
              </div>
              <!-- 新闻列表list -->
              <div class="news-R-content" v-html="ArticleContent">
                
              </div>
              <br />
            </div>
          </div>
        </div>

        <AppFooter />
    </div>
  </template>
  
  <script scoped>
  import AppTitle from '@/components/AppTitle.vue';
  import AppFooter from '@/components/AppFooter.vue';

  // import { getNewsList } from "@/api/index.js";
  import { getArticle } from "@/api/index.js";
  import { getMenu } from "@/api/index.js";


  export default {
    name: 'AppArticle',
    components: {
        AppTitle,AppFooter
    },
    data() {
      return {
        // 垂直菜单
        menuList: [],

        article_id: "",   // 具体文章的ID
        menu_id: "",      // 分类菜单的ID 
        menu_name: "",    // 分类菜单的名字

        // 文章
        ArticleContent:""   //具体文章内容
      }
    },

    mounted() {
      this.backToTop();
      this.getQuery();
      this.getMenuList();
    },
    watch: {
      $route() {
        this.backToTop();
        console.log('发生改变')
        this.getQuery();
        this.getMenuList();
      },
    },
    methods: {
      // 返回顶部
      backToTop(){
        // console.log('执行')
        document.documentElement.scrollTop = 0;
      },
      // 获取路由中的menu_name
      getQuery() {
        let article_id = this.$route.query.article_id;
        let menu_id = this.$route.query.menu_id;
        let menu_name = this.$route.query.menu_name;


        this.article_id = article_id;
        this.menu_id = menu_id;
        this.menu_name = menu_name;

        getArticle({article_id: article_id}).then(res => {
          this.ArticleContent = res.data.data.content;
        })
        
      },

      // get菜单list
      getMenuList() {
        getMenu().then(res=> {
        // 过滤状态停用
        let _list = res.data.data[0].menu.filter(function(val){
          return val.menu_status === 0;
        });
        this.menuList = _list;
        })
      },
      // 路由跳转
      goToPage(sid,menu_name) {
        console.log(menu_name)
        this.$router.push({path: '/news', query: {t: Date.parse(new Date()),sid: sid,menu_name: menu_name}});
      },
    }
  }

  </script>
  
  <style scoped>
    html,body {
        margin: 0;
        padding: 0;
    }

    .news {
        display: flex;
        flex-direction: row;
        min-height: 1300px;
        margin-top: 40px;
    }
    .news > .news-container {
        display: flex;
        flex-direction: row;
        min-height: 1300px;
        width: 1200px;
        margin: 0 auto;
    }
    .news-container > .news-L {
        display: flex;
        height: 1199px;
        width: 300px;
        /* background-color: #e5e5e5; */
    }

    /** 左侧菜单 */
    .news-L > .news-L-menu {
      width: 302px;
    }
    .news-L-menu > .news-L-menu-title {
      display: flex;
      height: 45px;
      width: 300px;
      background-color: #ffffff;
      font-size: 24px;font-weight: bold;letter-spacing: 2px;
      color: #9a0201;
    }

    .news-L-menu > .news-L-menu-list {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      width: 300px;
      background-color: #ffffff;
      font-size: 16px;
      color: #000000;
      border-bottom: 1px solid #e5e5e5;
    }
    .news-L-menu-list > .news-L-menu-list-a {
      position: absolute;
      top: 0;left: 0;
      height: 100%;width: 100%;
    }
    .news-L-menu > .news-L-menu-list:hover {
      background-color: #9a0201;
      color: #ffffff;
    }

    .news-container > .news-R {
        display: flex;
        flex-direction: column;
        min-height: 990px;
        width: 850px;margin-left: 50px;

    }
    /** 当前位置抬头 */
    .news-R > .news-R-title {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 850px;
        padding: 3px 0 0 0;
        height: 50px;
        background-color: #ffffff;
        border-bottom: 1px solid #9a0201;
    }
    .news-R > .news-R-title a:hover{
        color: #9a0201;
    }
    /** 新闻列表List */
    .news-R > .news-R-content { 
        min-height: 885px;
        width: 850px;

    }

   
    
  </style>

  <style>

    .news-R-content p img {

        width: 580px;
        height: auto;
        /* margin: 0 auto; */
    }
    .news-R-content img {

      width: 580px !important;
      height: auto;
      /* margin: 0 auto; */
    }
  </style>